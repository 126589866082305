<template lang="pug">
.wrapper(:style="customStyle")
    p.text-primary.text-h5.text-weight-bold.q-mb-lg.font-32.line-height-44(v-if="label") {{ label }}
    .relative-position
        q-btn(
            fab-mini
            @click="goPrev"
            v-show="prev"
            :style="standartPreview ? '' : 'left: 50px !important'").row.justify-center.items-center.prev
            q-icon(
                name="etabl:arrow_prev"
                size="xs")
        q-scroll-area(
            ref="slider"
            :thumb-style="thumbStyle"
            :bar-style="thumbStyle"
            @scroll="scroll"
            :style="`height: ${heightSlide}px;`")
            .row.no-wrap(:class="standartPreview ? '' : 'q-pr-md'")
                template(v-if="standartPreview")
                    preview-desktop(
                        v-for="(item, index) in slides"
                        :data="item"
                        :subtitle="subtitle"
                        :count="count"
                        :manufacture="manufacture"
                        :countSlides="slides.length"
                        :key="index"
                        :styles="styles"
                        country
                        @setSlideHeight="setSlideHeight"
                        :style="index == 0 || (index % countSlides == 0 && slides.length - index >= countSlides) ? '' : `margin-left: ${marginLeft}px !important`"
                        @showAnalogsList="showAnalogsList"
                        @previewReady="()=> $emit('previewReady')"
                        :sliderProductDay="sliderProductDay"
                    )
                template(v-else)
                    preview-small-desktop(
                        v-for="(item, index) in slides"
                        :data="item"
                        :subtitle="subtitle"
                        :count="count"
                        :manufacture="manufacture"
                        :countSlides="slides.length"
                        :key="index"
                        :bakset="basket"
                        :variant="variant"
                        :pickupVariant="pickupVariant"
                        country
                        :reorder="reorder"
                        :prodPage="prodPage"
                        :recommendationId="recommendationId"
                        :basketSlider="basketSlider"
                        :viewCart="viewCart",
                        @setSlideHeight="setSlideHeight"
                        @cleanRecom="$emit('cleanRecom')"
                        @reorderProduct="reorderProduct"
                        :sliderProductDay="sliderProductDay"
                        :style="`margin-left: ${marginLeft}px`"
                    )
        q-btn.row.justify-center.items-center.next(
            fab-mini
            @click="goNext"
            v-show="next && mounted"
            :style="standartPreview ? '' : 'right: 50px !important'"
        )
            q-icon(
                name="etabl:arrow_next"
                size="xs")
analogs-desktop(
    :showAnalogsDialog="showAnalogsDialog"
    @closeAnalogsDialog="closeAnalogsDialog"
)
</template>

<script>
import {computed, onMounted, ref, watch} from 'vue';
import {useStore} from 'vuex';
import PreviewDesktop from 'components/Preview/PreviewDesktop.vue';
import PreviewSmallDesktop from 'components/Preview/PreviewSmallDesktop.vue';
import basket from 'src/store/basket';

import AnalogsDesktop from '../Analogs/AnalogsDesktop.vue';

export default {
    name: 'ProductSliderDesktop',
    props: {
        label: {
            type: String,
            default: null,
        },
        slides: {
            type: Array,
            default: () => null,
            required: true,
        },
        subtitle: {
            type: Boolean,
            default: () => true,
        },
        manufacture: {
            type: Boolean,
            default: () => true,
        },
        count: {
            type: Boolean,
            default: () => true,
        },
        standartPreview: {
            type: Boolean,
            default: () => true,
        },
        recipe: {
            type: Boolean,
            default: () => false,
        },
        customStyle: {
            type: String,
        },
        basket: {
            type: Boolean,
            default: () => false,
        },
        variant: {
            type: String,
        },
        pickupVariant: {
            type: String,
        },
        recommendationId: {
            type: String,
        },
        reorder: {
            type: Boolean,
            default: () => false,
        },
        reorderedProduct: {
            type: Object,
        },
        prodPage: {
            type: Boolean,
            default: () => false,
        },
        slidesCountPreview: {
            type: Number,
        },
        basketSlider: {
            type: Boolean,
            default: () => false,
        },
        viewCart: {
            type: Boolean,
            default: () => false,
        },
        sliderProductDay: {
            type: Boolean,
            default: () => false,
        },
    },
    components: {
        PreviewDesktop,
        PreviewSmallDesktop,
        AnalogsDesktop,
    },
    setup(props, { emit }) {
        const store = useStore();
        const mounted = ref(false);

        const slider = ref(null);
        const next = ref(true);
        const widthSlide = ref(1310);
        const heightSlide = ref(438);
        const position = ref(0);
        const showAnalogsDialog = ref(false);

        // отображение кнопки назад
        const prev = computed(() => position.value > 0);

        // величина отступа между слайдами
        const marginLeft = computed(() => 16);

        // кол-во видимых слайдов
        const countSlides = computed(() => {
            if (process.env.CLIENT) {
                if (isActivAccessibility.value) {
                    return 2;
                }
                if (!props.standartPreview && !basket) {
                    return 3;
                } else if (!props.standartPreview && basket) {
                    return 2;
                } else if (window.innerWidth > 1280) {
                    return 5;
                } else if (window.innerWidth > 960 || props.recipe) {
                    return 4;
                } else return 3;
            }

            return 5;
        });
        const isActivAccessibility = computed(() => store.getters['styles/isActiv']);

        // стили для полосы прокрутки
        const thumbStyle = computed(() => {
            return {
                width: 0,
                opacity: 0,
            };
        });

        watch(() => props.slides, () => {
            goPrev(0);
        });

        // стили для превью
        const styles = computed(() => {
            return `width: ${(widthSlide.value - (marginLeft.value * (countSlides.value - 1))) / countSlides.value}px !important;`;
        });

        // установка высоты слайда
        const setSlideHeight = (e) => {
            heightSlide.value = e;
        };

        // метод прокрутки назад
        const goPrev = (distantion) => {
            if (!distantion) {
                position.value = distantion;
                slider.value.setScrollPosition('horizontal', distantion, 300);
            } else {
                position.value -= widthSlide.value;
                slider.value.setScrollPosition('horizontal', position.value, 300);
            }
        };

        // метод прокрутки вперед
        const goNext = () => {
            if (props.standartPreview) {
                position.value += widthSlide.value;
            } else {
                if (props.slidesCountPreview < 3) {
                    position.value += 440;
                } else {
                    position.value += 1146;
                }

            }

            slider.value.setScrollPosition('horizontal', position.value, 300);
        };

        // отображение кнопки вперёд
        const scroll = (source) => {
            next.value = !!(source.horizontalPercentage < 0.9989 && props.slides?.length > countSlides.value);
        };

        const showAnalogsList = () => {
            showAnalogsDialog.value = true;
        };

        const closeAnalogsDialog = () => {
            showAnalogsDialog.value = false;
        };

        const reorderProduct = (product) => {
            emit('reorderProduct', { product, reorderedProduct: props.reorderedProduct });
        };

        onMounted(() => {
            widthSlide.value = slider.value.$el?.clientWidth;
            mounted.value = true;
        });

        return {
            next,
            prev,
            slider,
            styles,
            goNext,
            goPrev,
            heightSlide,
            marginLeft,
            countSlides,
            setSlideHeight,
            thumbStyle,
            position,
            scroll,
            isActivAccessibility,
            showAnalogsDialog,
            showAnalogsList,
            closeAnalogsDialog,
            reorderProduct,
            mounted,
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(.q-scrollarea__container) {
    overflow: auto;
}

.next,
.prev {
    position: absolute;
    top: 50%;
    z-index: 10;
    width: 40px;
    background: $secondary;

    @media screen and (max-width: $breakpoint-mobile) {
        display: none;
    }
}

.next {
    right: 0;
    transform: translateX(50%) translateY(-50%);

    @media screen and (max-width: $breakpoint-mobile) {
        transform: translateX(30%);
    }
}

.prev {
    left: 0;
    transform: translateX(-50%) translateY(-50%);

    @media screen and (max-width: $breakpoint-mobile) {
        transform: translateX(-30%);
    }
}

.next > .q-btn,
.prev > .q-btn {
    width: 38px;
    height: 38px;
    display: block;
    background: $secondary;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 1280px) {
        width: 28px;
        height: 28px;
    }
}

.q-icon {
    font-size: 24px;
    @media (max-width: 1280px) {
        font-size: 8px;
    }
}

:deep(.VueCarousel-navigation) {
    display: none;
}

.scroll-slider {
    max-width: 100%;

    &__slide {
        width: 235px;
    }
}

.slide__wrapper {
    &:nth-of-type(1) {
        margin-left: 15px;
    }
}
</style>
